import * as types from './types'

export const openMessage = (payload) => ({
    type: types.OPEN,
    payload
});

export const closeMessage = () => ({
    type: types.CLOSE,
});

export const selectMessage = state => state.message;