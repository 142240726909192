import { createTheme } from '@mui/material/styles';

export default createTheme({
    palette: {
        primary: {
            main: '#6F62F9',
        },
        secondary: {
            main: '#027BAD',  
        },
        common: {
            main: 'white',
        },
        background: {
            default: '#F5F5F5',
        },
        error: {
            main: '#E74C3C', // Vermelho (erros)
            contrastText: '#FFFFFF', // Branco (texto do verde)
        },
        success: {
            main: '#27AE60', // Verde (sucesso)
            contrastText: '#FFFFFF', // Branco (texto do verde)
        },
        warning: {
            main: '#FFC107',
            contrastText: '#fff'
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: { 
                    '& .MuiInputBase-root': {
                        fontFamily: 'Inter',
                    },
                    '& .MuiInputLabel-root': {
                        color: 'grey.600', 
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                        "-webkit-text-fill-color": "black !important",
                    },
                },
                
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#1e120d"
                },
                h1: { 
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                    fontSize: "36px",
                },
                h2: {
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                    fontSize: "33px",
                },
                h3: { 
                    fontFamily: "Quicksand",
                    fontSize: "30px",
                },
                h4: {
                    fontFamily: "Quicksand",
                    fontSize: "24px",
                },
                h5: {
                    fontFamily: "Quicksand",
                    fontWeight: "300",
                    fontSize: "19px",
                },
                h6: {
                    fontFamily: "Quicksand",
                    fontWeight: 'bold',
                    fontSize: "17px",
                },
                p: {
                    fontFamily: "Inter",
                    fontSize: "14px",
                },
                body1: {
                    fontFamily: "Inter",
                    fontWeight: "300",
                    fontSize: "17px",
                },
                body2: {
                    fontFamily: "Inter",
                    fontSize: "14px",
                },
                a: {
                    fontFamily: "Inter",
                    fontWeight: 'bold',
                    fontSize: "18px",
                },
            }
        },
        MuiButton:{
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        }
    },
  });
