import React, { useContext, useState } from 'react';

import {
    Grid,
    Typography,
    IconButton,
    Box,
    Paper,
    Button,
    useMediaQuery,
    Dialog,
    DialogContent,
    DialogActions,
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../redux/user/actions';
import { Article, Menu } from '@mui/icons-material';
import { Context } from '../../routes/context';
import { generatePayment, verifyPayment } from '../../data/utils';

export default function Topbar() {

    const user = useSelector(selectUser);

    const dispatch = useDispatch();
    
    const {setSidebarOpen} = useContext(Context);

    const [responsePayment, setResponsePayment] = useState(false);
    const [linkBuyMercadoPago, setLinkBuyMercadoPago] = useState(false);

    const handleClose = () => {
        setLinkBuyMercadoPago(false);
    };

    const handleClick = async () => {
        let response = await generatePayment(9.9, 1, user.data.email, user.uid);

        if(response){
            // dispatch(updatePayIdOfUserInRealtimeDataBase(user.uid, response.id.toString()));
            setResponsePayment(response);
            setLinkBuyMercadoPago(response.point_of_interaction.transaction_data.ticket_url);
        }
    }


    return linkBuyMercadoPago ?
        <Dialog
            fullScreen
            open={linkBuyMercadoPago}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >   
            <DialogContent>
                <Box sx={{height: '100%'}}>
                    <iframe src={linkBuyMercadoPago} width="100%" height="100%" title="link_buy" />
                </Box>
            </DialogContent>
            <DialogActions sx={{m: 2}}>
                {responsePayment &&
                <Button 
                    onClick={()=> verifyPayment(responsePayment, setLinkBuyMercadoPago, setResponsePayment, dispatch)}
                    variant="contained"
                    color='success'
                    >
                        Status pagamento
                    </Button>
                }
                <Button 
                onClick={handleClose} 
                variant="contained"
                color='secondary'>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
        :
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 3
            // alignItems: 'center',
        }}
        >
            <Grid
            container
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
                width: '100%',
                height: '60px',
                backgroundColor: 'primary.main',
                px: {xs: 1, md: 3},
            }}
            >
                <IconButton
                    size='medium' 
                    onClick={()=>{setSidebarOpen(true)}}
                    sx={{
                    // margin: '10px 10px'
                    }}
                    >
                        <Menu
                        sx={{
                            fontSize: 45,
                            color: 'common.white'
                        }}
                        />
                </IconButton>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        // justifyContent: 'space-evenly',
                        // width: '80%',
                        color: 'common.white',
                        gap: 0.5,
                    }}>
                        <Article
                        />
                        <Typography
                        color={'common.white'}
                        variant="h6"
                        fontSize={{xs: '90%', md: '17px'}}
                        >
                            {!user.data || user.data.qntdRedacoes == 0 ? 
                            '0 Redações Automáticas restantes' : 
                            user.data.qntdRedacoes == 1 ?
                            '1 Redação Automática restante' :
                            `${user.data.qntdRedacoes} Redações Automáticas restantes`
                            }
                            
                        </Typography>
                </Box>
            </Grid>
           

            <Button
            variant='contained'
            color='error'
            sx={{
                fontSize: 15,
                mt: 2,
                display: user.data.qntdRedacoes == 0 ? 'block' : 'none',
            }}
            onClick={handleClick}
            >
                Quero minha Redação Automática agora
            </Button>
        </Box>
}