import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Router from './routes'
import {Provider} from 'react-redux'
import store from './redux/store';

import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);
ReactPixel.pageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router/>
    </Provider>
  </React.StrictMode>
);