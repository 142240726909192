import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_API_WRITE_KEY,
});

const openai = new OpenAIApi(configuration);

delete configuration.baseOptions.headers['User-Agent'];

export async function apiCall(tema){
  const response = await openai.createCompletion({
    model: "gpt-3.5-turbo-instruct",
    prompt: `Aluno: Crie uma redação modelo ENEM com apenas 1 parágrafo de introdução com uma citação real,apenas 2 parágrafos de desenvolvimento e apenas 1 parágrafo de conclusão com uma proposta de intervenção falando o que, quem e como a proposta deve ser feita com o tema ${tema}. Utilize de 300 a 350 palavras. Professora:`,
    temperature: 0.5,
    max_tokens: 3000,
    top_p: 1,
    frequency_penalty: 0.3,
    presence_penalty: 0,
  });

  return response;
}