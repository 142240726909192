import React from 'react';
import MasterAuthPage from '../masterAuthPage';

import {Formik} from 'formik'

import {
    TextField,
    Button,
    Container,
    Box,
    Typography
} from '@mui/material';

import { recoverPasswordValidationSchema } from '../../services/schemas';
import { recoverPasswordFirebase } from '../../redux/user/actions'

import { useNavigate } from 'react-router-dom';

import "./styles.scss"
import { useDispatch } from 'react-redux';

export default function Login(){

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <MasterAuthPage>
            <Typography
            variant='h3'
            color='primary'
            sx={{
                mb: 2,
            }}
            >
                Recupere sua Senha
            </Typography>
            <Typography 
            variant='body2' 
            sx={{
                // my: 2,
                textAlign: 'left'
            }}>
                Não tem uma conta?
                <Typography 
                variant='span' 
                color='primary'
                onClick={()=>navigate("/signUp")}
                sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.8
                    },
                    '&:active': {
                        opacity: 0.6
                    }
                }}
                > Criar agora</Typography>
            </Typography>
             <Formik
                    validationSchema={recoverPasswordValidationSchema}
                    initialValues={{
                        email: '',
                    }}
                    onSubmit={(values,actions) => {
                        dispatch(recoverPasswordFirebase(values.email))
                        actions.resetForm({
                            values: {
                                email: '',
                            }
                        })
                    }}
                >
                {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                }) => (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mt: 2,
                                
                                '& .MuiInputBase-root': {
                                    borderRadius: 5
                                },
                            }}
                        >
                            <TextField
                                label="E-mail"
                                type={'email'}
                                variant="outlined"
                                onBlur={handleBlur('email')}
                                value={values.email}
                                onChangeCapture={handleChange('email')}
                                error={errors.email && touched.email}
                                inputProps={{
                                    style: {
                                        color: 'black',
                                    }
                                }}
                                
                            />
                            {(errors.email && touched.email) &&
                            <Typography 
                            variant='p'
                            color='error'
                            sx={{
                                mt: 1,
                                textAlign: 'left'
                            }}>
                                {errors.email}
                            </Typography>
                            }
                        </Box>

                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'right',
                        }}>
                            <Typography 
                            variant='body2' 
                            color='primary.main'
                            onClick={()=>navigate("/login")}
                            sx={{
                                my: 2,
                                cursor: 'pointer',
                                '&:hover': {
                                    opacity: 0.8
                                },
                                '&:active': {
                                    opacity: 0.6
                                },
                                fontWeight: 'bold'
                            }}>
                                Voltar para o login
                            </Typography>

                            <Button
                            variant="contained"
                            color='primary'
                            className='btnLogin'
                            sx={{
                                textTransform: 'none',
                                fontWeight: 'bold',
                                borderRadius: 3,
                            }}
                            onClick={handleSubmit}>
                                Receber link de recuperação
                            </Button>

                        

                        </Box>
                    </>
                )}
                </Formik>
        </MasterAuthPage>
    )
}