import * as types from './types'

import { ref, get, child } from "firebase/database";
import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';

import { auth, database } from '../../services/firebase';
import { createUserInRealtimeDataBase, setGoogleUserInRealtimeDatabase } from '../../services/funcsFirebase';

import { openMessage } from '../../redux/message/actions';

const loginSuccess = (uid)=> ({
    type: types.LOGIN,
    payload: uid,
});

const updateUserLoggedData = (user) => ({
    type: types.UPDATE_LOGGED_DATA,
    payload: user,
});

const logoutSuccess = () => ({
    type: types.LOGOUT,
});

export const signUpFirebase = (values) => {
    return function (dispatch){
        createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
            var user = userCredential.user;
            createUserInRealtimeDataBase(values, user.uid);
            dispatch(openMessage({
                text: "Conta criada com sucesso!",
            }))
            dispatch(updateLoggedData(user.uid));
        })
        .catch(() => {
            dispatch(openMessage({
                text: "Email já existe!",
                color: "erro"
            }))
        });
    }
}

export const signInFirebase = (values) => {
    return function (dispatch){
        signInWithEmailAndPassword(auth, values.email, values.password)
        .then((user)=>{
            dispatch(loginSuccess(user.user.uid));
            dispatch(updateLoggedData(user.user.uid));
        })
        .catch(() => {
            dispatch(openMessage({
                text: "Email ou senha inválidos!",
                color: "erro"
            }))
        });
    }
}

export function signInGoogleFirebase(){
    return function (dispatch){
        const provider = new GoogleAuthProvider();

        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
            dispatch(setGoogleUserInRealtimeDatabase(user), loginSuccess());
        }).catch((error) => {
            dispatch(openMessage({
                text: "Erro ao logar com o Google!",
                color: "erro"
            }))
        });
    }
}

export const signOutFirebase = () => {
    return function (dispatch){
        signOut(auth)
        .then(()=> {
            dispatch(logoutSuccess());
        })
        .catch((error) => {
            dispatch(openMessage({
                text: "Erro ao deslogar.",
                color: "erro"
            }))
        });
    }
}

export const recoverPasswordFirebase = (email) => {
    return function (dispatch) {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            dispatch(openMessage({
                text: `Email de recuperação enviado para: ${email}.`,
            }))
        })
        .catch((error) => {
            if(error.code === "auth/user-not-found"){
                dispatch(openMessage({
                    text: "Usuário não encontrado!",
                    color: 'erro'
                }))
            } else {
                dispatch(openMessage({
                    text: `Erro ao pedir recebimento de email.`,
                    color: 'erro'
                }))
            }
        });
    }
}

export const updateLoggedData = (uid) => {
    return function (dispatch){
        const dbRef = ref(database);
        get(child(dbRef, `users/${uid}`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                let data = {
                    name: snapshot.val().name,
                    email: snapshot.val().email,
                    qntdRedacoes: snapshot.val().qntdRedacoes,
                    payId: snapshot.val().payId,
                    telefone: snapshot.val().telefone || "",
                };
                let userCopy = {...data};
                dispatch(updateUserLoggedData(userCopy));
            } 
        }).catch((error) => {
            // console.error(error);
        });
    }
}

export const observer = () => {
    return function (dispatch){
        // nunca para de escutar 
        onAuthStateChanged(auth, (user) => {
            if (user) {
                sessionStorage.setItem("uid", user.uid);
                dispatch(loginSuccess(user.uid));
                dispatch(updateLoggedData(user.uid));
            } else {
                sessionStorage.removeItem("uid");
                dispatch(logoutSuccess());
            }
        });   
    }
}

export const selectUser = state => state.user;