import React, {useState} from 'react'
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessage, closeMessage } from '../../redux/message/actions';

export default function MessageDialog(){

    const dispatch = useDispatch();

    const message = useSelector(selectMessage);

    const handleClose = () => {
        dispatch(closeMessage());
    };

    return (
        <Dialog
            open={message.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"
            sx={{
                fontSize: 22,
            }}>
                {"Mensagem"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText 
                variant='p' 
                sx={{
                    fontSize: 20,
                }} 
                color={message.color} 
                id="alert-dialog-description">
                    {message.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                variant="contained"
                color='secondary'
                onClick={handleClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}