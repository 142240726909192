import * as React from 'react';
import {Card, CardHeader, CardContent, Avatar,
Typography,
Box} from '@mui/material'
import StarIcon from '@mui/icons-material/Star';

export default function DepoimentoCard(props) {

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar src={props.icon}/>
                }
                title={props.name}
                
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {props.text}
                </Typography>

                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2
                }}
                >
                    {[...Array(5)].map((e,index) => (
                        <StarIcon key={index} color='warning'/>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
}