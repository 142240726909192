import React, {useContext, useEffect, useState} from 'react';

import {TextField, 
        Button, 
        Container,
        Grid,
        Typography,} from '@mui/material';
        
import LoadingButton from '@mui/lab/LoadingButton';

import { apiCall } from '../../services/apiWrite';

import { gerarRedacaoValidationSchema } from '../../services/schemas';

import { Formik } from 'formik'

import { updateRedacoesOfUserInRealtimeDataBase } from '../../services/funcsFirebase';


import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/user/actions';
import { openMessage } from '../../redux/message/actions';


import MasterAppPage from '../masterAppPage';

import { Context } from '../../routes/context';

export default function GerarRedacao(){

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    
    const [redacao, setRedacao] = useState(localStorage.getItem("ultimaRedacao"));
    const [loading, isLoading] = useState(false);

    const {setOpenShopDialog} = useContext(Context);

    // useEffect(()=> {
    //     // dispatch(openMessage({
    //     //     text: "Site em manutenção! Volte mais tarde!"
    //     // }))
    //     if(typeof user.data.payId != "undefined" && user.data.payId != ""){
    //         verificaCancelamentoPagamento();
    //     }
    // }, [user.data.payId]);

    function copyRedacao(){
        navigator.clipboard.writeText(redacao)
        dispatch(openMessage({
            text: "Texto copiado!",
        }));
    }

    function userHasRedacao(){
        return user.data.qntdRedacoes !== 0; 
    }

    return (
        <MasterAppPage>
            <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mb: 6,
            }}>
                <Typography 
                variant="h3"
                color={'primary.main'}
                sx={{
                    fontSize: {xs: '24px', md: '30px'},
                }}
                >
                    Gerar Redação
                </Typography>

                <Button 
                sx={{
                    width: [220,300],
                }}
                variant="contained"
                color='success'
                onClick={()=>{
                    setOpenShopDialog(true);
                }}>
                        Comprar redações
                </Button>
            </Grid>

            <Formik
                validationSchema={gerarRedacaoValidationSchema}
                initialValues={{
                    tema: '',
                }}
                onSubmit={(values, actions) => {
                    if(userHasRedacao()){
                        if(values.tema.length > 150){{
                            dispatch(openMessage({
                                text: "Tema muito longo!",
                                color: "erro"
                            }));
                            isLoading(false);
                        }} else {
                            isLoading(true);
                            apiCall(values.tema.trim())
                            .then((response)=>{
                                setRedacao(response.data.choices[0].text);
                                localStorage.setItem("ultimaRedacao", response.data.choices[0].text);
                                dispatch(updateRedacoesOfUserInRealtimeDataBase(user.uid, -1));
                                isLoading(false);
                            })
                            .catch((e)=>{
                                dispatch(openMessage({
                                    text: "Erro ao fazer redação! Sua redação não foi descontada!",
                                    color: "erro"
                                }));
                                isLoading(false);
                            })
                        }
                    } else {
                        dispatch(openMessage({
                            text: "Ops. Você não tem redações restantes. Compre para poder gerá-las.",
                            color: "erro"
                        }));
                        isLoading(false);
                    }
                }}
            >
            {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            }) => (
                <Grid container sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    mt: 4
                }}>
                    <Grid item sx={{
                        display: "flex",
                        flexDirection: "column",
                        mb: 4
                    }}>
                        <TextField
                            label="Qual o tema da sua redação ENEM?"
                            type={'text'}
                            rows={3}
                            multiline
                            onBlur={handleBlur('tema')}
                            value={values.tema}
                            onChangeCapture={handleChange('tema')}
                            disabled={loading}
                            sx={{
                                mb: errors.tema && touched.tema ? "0" : 2
                            }}
                        />
                        {(errors.tema && touched.tema) &&
                        <Typography 
                        variant='p'
                        color='error.main'
                        sx={{
                            my:2
                        }}>
                            {errors.tema}
                        </Typography>
                        }

                        <LoadingButton 
                        sx={{
                            width: ["100%", 300],
                        }}
                        variant='contained'
                        loading={loading}
                        onClick={handleSubmit}
                        disabled={loading}>
                            Gerar
                        </LoadingButton>
                    </Grid>

                    <Grid item sx={{
                        display: "flex",
                        flexDirection: "column",
                        mb: 5
                    }}>
                        <TextField
                        label="Resultado"
                        type={"text"}
                        multiline
                        minRows={3}
                        disabled={true}
                        value={redacao ? redacao.trim() : ''}
                        sx={{
                            mb: 2
                        }}
                        />

                        <Grid 
                        container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: ["space-between", "start"],
                            gap: [0,1,3]
                        }}>
                            <Button
                            sx={{
                                width: ["46%", 200, 300],
                            }}
                            variant='contained'
                            color='primary'
                            disabled={redacao ? false : true}
                            onClick={()=>{copyRedacao()}}>
                                Copiar
                            </Button>

                            <Button 
                            sx={{
                                width: ["46%", 200, 300],
                            }}
                            variant='contained'
                            color='secondary'
                            disabled={redacao ? false : true}
                            onClick={()=>{window.location.href = "mailto:redacaoautomatica@gmail.com"}}>
                                Dar feedback da redação
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            </Formik>
        </MasterAppPage>
    )
}