import React, {useState} from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { telefoneNotRegisteredValidationSchema } from '../../services/schemas';
import { updateTelefoneOfUserInRealtimeDataBase } from '../../services/funcsFirebase';

import { 
    Box, 
    TextField,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

import { openMessage } from '../../redux/message/actions';
import { selectUser } from '../../redux/user/actions';

export default function TelefoneRegistrationDialog(props){

    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [open, setOpen] = useState(props.open);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog            
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"
            sx={{
                fontSize: 20,
            }}>
                {"Deseja cadastrar o telefone para ter acesso a um curso exclusivo de redação para o enem?"}
            </DialogTitle>
            <DialogContent>
            <Formik
                    validationSchema={telefoneNotRegisteredValidationSchema}
                    initialValues={{
                        telefone: '',
                    }}
                    onSubmit={(values,actions) => {
                        dispatch(updateTelefoneOfUserInRealtimeDataBase(user.uid, values.telefone));
                        dispatch(openMessage({
                            text: "Telefone cadastrado!"
                        }))
                        handleClose();
                    }}
                >
                {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                }) => (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                my: 2,
                            }}
                        >
                            <TextField
                                label="Telefone com DDD"
                                type={'tel'}
                                variant="outlined"
                                onBlur={handleBlur('telefone')}
                                value={values.telefone}
                                onChangeCapture={handleChange('telefone')}
                                inputProps={{
                                    maxLength: 11,
                                }}
                                sx={{
                                    width: '100%',
                                }}
                            />
                            {(errors.telefone && touched.telefone) &&
                            <Typography
                            variant='p'
                            color='error'
                            sx={{
                                mt: 1,
                                textAlign: 'left'
                            }}>
                                {errors.telefone}
                            </Typography>
                            }
                        </Box>

                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                        }}>
                            <Button 
                            variant="contained"
                            color='primary'
                            onClick={handleSubmit}>
                                Cadastrar
                            </Button>
                        </Box>
                    </>
                )}
                </Formik>
            </DialogContent>
            <DialogActions>
                <Button 
                variant="text"
                color='secondary'
                onClick={handleClose}>Não</Button>
            </DialogActions>
        </Dialog>
    )
}