import * as yup from 'yup'

export const signInValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Por favor, digite um email válido.")
      .required('Email é obrigatório.'),
    password: yup
      .string()
      .min(6, ({ min }) => `Senha precisa ter no mínimo ${min} caracteres.`)
      .required('Senha é obrigatória.'),
})

export const signUpValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(4, ({ min }) => `Nome precisa ter no mínimo ${min} caracteres.`)
    .required('Nome é obrigatório.'),
  email: yup
    .string()
    .email("Por favor, digite um email válido.")
    .required('Email é obrigatório.'),
  password: yup
    .string()
    .min(6, ({ min }) => `Senha precisa ter no mínimo ${min} caracteres.`)
    .required('Senha é obrigatória.'),
  telefone: yup
    .string()
    .min(10, ({ min }) => `Telefone precisa ter no mínimo ${min} números.`)
    .matches(/^[0-9]+$/, "Digite somente números.")
    .optional(),
})

export const recoverPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Por favor, digite um email válido.")
    .required('Email é obrigatório.'),
})

export const telefoneNotRegisteredValidationSchema = yup.object().shape({
  telefone: yup
    .string()
    .min(10, ({ min }) => `Telefone precisa ter no mínimo ${min} números.`)
    .matches(/^[0-9]+$/, "Digite somente números.")
    .required('Telefone é obrigatório.'),
})

export const gerarRedacaoValidationSchema = yup.object().shape({
  tema: yup
  .string()
  .min(15, ({ min }) => `Tema precisa ter no mínimo ${min} caracteres.`)
  .required('Tema é obrigatório.'),
})