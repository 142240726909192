export const redacoesInfo = [
    {
        "quantity": 1,
        "value": 9.9,
        "lastValue": 39.9,
    },
    {
        "quantity": 5,
        "value": 14.9,
        "lastValue": 129.9,
    },
    {
        "quantity": 10,
        "value": 29.9,
        "lastValue": 289.9,
    }
]