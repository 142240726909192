import homem1 from '../assets/pessoas/homem1.jpeg'
import homem2 from '../assets/pessoas/homem2.jpeg'
import homem3 from '../assets/pessoas/homem3.jpeg'
import homem4 from '../assets/pessoas/homem4.jpeg'
import homem5 from '../assets/pessoas/homem5.jpeg'

import mulher1 from '../assets/pessoas/mulher1.jpeg'
import mulher2 from '../assets/pessoas/mulher2.jpeg'
import mulher3 from '../assets/pessoas/mulher3.jpeg'
import mulher4 from '../assets/pessoas/mulher4.jpeg'
import mulher5 from '../assets/pessoas/mulher5.jpeg'

export const textoDepoimentos = [
    {
    "id": 1,
    "text": "Cara, essa ferramenta de redação automática pro ENEM me salvou! Eu tava super nervoso com a prova e não tinha muita confiança na minha escrita, mas com essa ajuda consegui tirar uma nota top. Recomendo demais!",
    "name": "Lucas Menezes",
    "icon": homem1
    },
    {
    "id": 2,
    "text": "Eu nunca fui muito boa em redação, mas graças a essa ferramenta de redação automática pro ENEM, consegui melhorar minha nota e garantir uma vaga na universidade dos meus sonhos. Muito obrigada!",
    "name": "Juliana Almeida",
    "icon": mulher2
    },
    {
    "id": 3,
    "text": "Vocês não têm ideia do quanto essa ferramenta me ajudou! Sempre me enrolei pra escrever redações, mas com esse produto consegui tirar uma nota excelente no ENEM. Valeu!",
    "name": "Gabriel Silva",
    "icon": homem3
    },
    // {
    // "id": 4,
    // "text": "Gente, essa ferramenta de redação automática é incrível! Minha nota no ENEM aumentou e eu consegui passar no curso que eu queria. Recomendo muito!",
    // "name": "Amanda Gomes",
    // "icon": mulher2
    // },
    // {
    // "id": 5,
    // "text": "Essa ferramenta de redação automática pro ENEM é demais! Me deu confiança e me ajudou a organizar melhor minhas ideias na hora da prova. Minha nota subiu bastante, e agora tô na faculdade!",
    // "name": "Pedro Santos",
    // "icon": homem3
    // },
    // {
    // "id": 6,
    // "text": "Eu sempre tive dificuldades em escrever redações, mas essa ferramenta de redação automática pro ENEM mudou minha vida. Consegui uma nota ótima e fui aprovada na universidade que eu queria. Muito obrigada!",
    // "name": "Mariana Oliveira",
    // "icon": mulher3
    // },
    // {
    // "id": 7,
    // "text": "Mano, essa ferramenta de redação automática é sensacional! Eu tava com medo de ir mal no ENEM, mas minha nota subiu muito depois que eu comecei a usar. Super recomendo!",
    // "name": "Felipe Costa",
    // "icon": homem4
    // },
    // {
    // "id": 8,
    // "text": "Eu estava super nervosa com a redação do ENEM, mas essa ferramenta me ajudou demais! Consegui organizar melhor minhas ideias e tirar uma nota ótima. Vale a pena!",
    // "name": "Isabela Fernandes",
    // "icon": mulher4
    // },
    // {
    // "id": 9,
    // "text": "Sério, essa ferramenta de redação automática pro ENEM foi a melhor coisa que aconteceu pra mim. Minha nota melhorou muito e consegui entrar na faculdade que eu queria. Recomendo muito!",
    // "name": "Thiago Ribeiro",
    // "icon": homem5
    // },
    // {
    // "id": 10,
    // "text": "A ferramenta de redação automática pro ENEM me ajudou a conquistar a nota que eu precisava pra passar no curso dos meus sonhos. Muito obrigada, valeu demais!",
    // "name": "Camila Soares",
    // "icon": mulher5
    // }
]