import server from '../services/server';
import { openMessage } from '../redux/message/actions';

export const generatePayment = async (value, qntd, userEmail, userUid) => {
    const body = {
        "value": value,
        "qntd": qntd,
        "userEmail": userEmail,
        "uid": userUid ?? sessionStorage.getItem("uid"),
    }

    return await server.post("/payments", body)
    .then(response => {
        return response.data.response;
    }).catch(err => {
        console.log(err);
        return null;
    })
}

export const getPayment = async (id) => {
    return await server.get(`/payments/${id}`)
    .then(response => {
        return response.data.response
    })
    .catch(err => {
        return {}
    })
}

export const verifyPayment = async (responsePayment, setLinkBuyMercadoPago, setResponsePayment, dispatch) => {
    
    if(responsePayment){
        const response = await getPayment(responsePayment.id);
        if(response){
            if(response.status === "approved"){
                
                dispatch(openMessage({
                    text: "Pagamento aprovado!"
                }))

                setLinkBuyMercadoPago(false);
                setResponsePayment(false);
            } else if(response.status === "pending"){
                dispatch(openMessage({
                    text: "Pagamento pendente!"
                }))
            } else {
                setResponsePayment(response);
            }
        }
    } else {
        setLinkBuyMercadoPago(false);
    }
}