import React, {useContext, useState} from 'react'
import ArticleIcon from '@mui/icons-material/Article';
import {
Button,
Dialog,
DialogActions,
DialogContent,
Grid,
Typography,
Paper,
Box
} from '@mui/material';

import {redacoesInfo} from "../../data/redacoes.js"

import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../redux/user/actions';
import { openMessage } from '../../redux/message/actions.js';

import server from '../../services/server.js';

import "./styles.scss"
import { Container } from '@mui/material';
import { textoDepoimentos } from '../../data/depoimentos.js';
import DepoimentoCard from '../depoimentoCard/index.jsx';
import { Context } from '../../routes/context.jsx';
import { generatePayment, verifyPayment } from '../../data/utils.js';

export default function ShopDialog(props){

    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [responsePayment, setResponsePayment] = useState(false);
    const [linkBuyMercadoPago, setLinkBuyMercadoPago] = useState(false);

    const {openShopDialog, setOpenShopDialog} = useContext(Context);

    const handleClose = () => {
        setOpenShopDialog(false);
    };

    const handleClick = async (qntd, value) => {
        let response = await generatePayment(value, qntd, user.data.email, user.uid);

        if(response){
            // dispatch(updatePayIdOfUserInRealtimeDataBase(user.uid, response.id.toString()));
            setResponsePayment(response);
            setLinkBuyMercadoPago(response.point_of_interaction.transaction_data.ticket_url);
        }
    }

    return linkBuyMercadoPago ?
        <Dialog
            fullScreen
            open={openShopDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >   
            <DialogContent>
                <Box sx={{height: '100%'}}>
                    <iframe src={linkBuyMercadoPago} width="100%" height="100%" title="link_buy" />
                </Box>
            </DialogContent>
            <DialogActions sx={{m: 2}}>
                {responsePayment &&
                <Button 
                    onClick={()=> verifyPayment(responsePayment, setLinkBuyMercadoPago, setResponsePayment, dispatch)}
                    variant="contained"
                    color='success'
                    >
                        Status pagamento
                    </Button>
                }
                <Button 
                disabled={loading} 
                onClick={handleClose} 
                variant="contained"
                color='secondary'>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
        :
        <Dialog
            fullScreen
            open={openShopDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >   
            <DialogContent>
                <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'primary.main',
                    width: '70px',
                    height: '70px',
                    borderRadius: '50%',
                    mb: '30px',
                    
                }}>
                    <ArticleIcon
                        sx={{ fontSize: 40, color: 'common.white' }}
                    />
                </Container>

                <Grid 
                container
                spacing={4}
                sx={{
                }}
                >
                    {redacoesInfo.map((e, id) => (
                        <Grid 
                        item 
                        xs={10} sm={6} lg={4}
                        sx={{
                            margin: ['auto']
                        }}
                        key={e.value}>
                            <Paper
                                elevation={1}
                                sx={{
                                    backgroundColor: 'primary.main',
                                    padding: '20px',
                                    borderRadius: '10px',
                                }}
                            >
                                <Box
                                sx={{
                                    textAlign: 'center',
                                    mb: '1rem',
                                }}>
                                    <Typography
                                        variant='h5'
                                        color={'common.white'}
                                    >
                                        {e.quantity} {e.quantity == 1 ? 'redação' : 'redações'}
                                    </Typography>
                                </Box>
                
                                <Box
                                sx={{
                                    textAlign: 'center',
                                    color: 'common.white',
                                    mb: '1rem',
                                }}>
                                    <Box>
                                        <Typography
                                        variant='h4'
                                        color={'common.white'}
                                        sx={{
                                            textDecoration: 'line-through',
                                            
                                        }}
                                        >
                                            {e.lastValue.toFixed(2).replace(".", ",")}
                                        </Typography>
                                        <Typography
                                        color={'common.white'}>
                                            para
                                        </Typography>
                                    </Box>
                                    <Typography 
                                    variant='h5'
                                    color={'common.white'}
                                    sx={{
                                            letterSpacing: '0.1rem',
                                            
                                        }}
                                    >
                                        {e.value.toFixed(2).replace(".", ",")}
                                    </Typography>
                                </Box>

                                <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                >
                                    <Button 
                                    color='success'
                                    disabled={loading} 
                                    onClick={()=> {handleClick(e.quantity, e.value)}} 
                                    variant="contained"
                                    size='large'>
                                        Comprar
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    ))
                    }
                </Grid>

                <Grid
                spacing={4}
                container
                sx={{
                    mt: 4,
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    {textoDepoimentos.map((e) => (
                        <Grid
                        key={e.name}
                        item>
                            <DepoimentoCard 
                            icon={e.icon}
                            text={e.text}
                            name={e.name}
                            />
                        </Grid>
                    ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions sx={{m: 2}}>
                <Button 
                color='secondary'
                variant="contained"
                 onClick={handleClose}>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
}