import React from 'react';
import MasterAuthPage from '../masterAuthPage';

import {Formik } from 'formik'

import {
    TextField,
    Button,
    Container,
    Box,
    Typography,
} from '@mui/material';

import { signUpValidationSchema } from '../../services/schemas';
import { signUpFirebase } from '../../redux/user/actions';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import "./styles.scss"

export default function Signup(){

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <MasterAuthPage>
            <Typography
            variant='h3'
            color='primary'
            sx={{
                mb: 2,
            }}
            >
                Crie sua conta
            </Typography>

            <Typography 
            variant='body2' 
            sx={{
                // my: 2,
                textAlign: 'left'
            }}>
                Já tem uma conta?
                <Typography 
                variant='span' 
                color='primary'
                onClick={()=>navigate("/login")}
                sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.8
                    },
                    '&:active': {
                        opacity: 0.6
                    }
                }}
                > Fazer Login</Typography>
            </Typography>

            <Formik
                validationSchema={signUpValidationSchema}
                initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    telefone: '',
                }}
                onSubmit={(values,actions) => {
                    dispatch(signUpFirebase(values));
                }}
            >
            {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            }) => (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 2,
                            
                            '& .MuiInputBase-root': {
                                borderRadius: 5
                            },
                        }}
                    >
                        <TextField
                            label="Nome"
                            type={'text'}
                            variant="outlined"
                            onBlur={handleBlur('name')}
                            value={values.name}
                            onChangeCapture={handleChange('name')}
                            sx={{
                                width: '100%',
                            }}
                        />
                        {(errors.name && touched.name) &&
                        <Typography 
                        variant='p'
                        color='error'
                        sx={{
                            mt: 1,
                            textAlign: 'left'
                        }}>
                            {errors.name}
                        </Typography>
                        }
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 2,
                            '& .MuiInputBase-root': {
                                borderRadius: 5
                            },
                        }}
                    >
                        <TextField
                            label="Telefone com DDD"
                            type={'tel'}
                            variant="outlined"
                            onBlur={handleBlur('telefone')}
                            value={values.telefone}
                            onChangeCapture={handleChange('telefone')}
                            inputProps={{
                                maxLength: 11,
                            }}
                            sx={{
                                width: '100%',
                            }}
                        />
                        {(errors.telefone && touched.telefone) &&
                        <Typography 
                        variant='p'
                        color='error'
                        sx={{
                            mt: 1,
                            textAlign: 'left'
                        }}>
                            {errors.telefone}
                        </Typography>
                        }
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 2,
                            
                            '& .MuiInputBase-root': {
                                borderRadius: 5
                            },
                        }}
                    >
                        <TextField
                            label="Email"
                            type={'email'}
                            variant="outlined"
                            onBlur={handleBlur('email')}
                            value={values.email}
                            onChangeCapture={handleChange('email')}
                            sx={{
                                width: '100%',
                            }}
                        />
                        {(errors.email && touched.email) &&
                        <Typography 
                        variant='p'
                        color='error'
                        sx={{
                            mt: 1,
                            textAlign: 'left'
                        }}>
                            {errors.email}
                        </Typography>
                        }
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 2,
                            
                            '& .MuiInputBase-root': {
                                borderRadius: 5
                            },
                        }}
                    >
                        <TextField
                            label="Senha"
                            type={'password'}
                            variant="outlined"
                            onBlur={handleBlur('password')}
                            value={values.password}
                            onChangeCapture={handleChange('password')}
                            sx={{
                                width: '100%',
                            }}
                        />
                        {(errors.password && touched.password) &&
                        <Typography 
                        variant='p'
                        color='error'
                        sx={{
                            mt: 1,
                            textAlign: 'left'
                        }}>
                            {errors.password}
                        </Typography>
                        }
                    </Box>

                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right',
                    }}>
                        <Button
                        variant="contained"
                        color='primary'
                        className='btnLogin'
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            borderRadius: 3,
                            mt: 2,
                        }}
                        onClick={handleSubmit}>
                            Criar Agora
                        </Button>
                    </Box>
                </>
            )}
            </Formik>
            
        </MasterAuthPage>
    )
}