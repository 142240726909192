import React, {useEffect, useState} from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import GerarRedacao from '../pages/gerarRedacao'
import Depoimentos from '../pages/depoimentos'
import ShopDialog from '../components/shopDialog'
import MessageDialog from '../components/messageDialog';
import Sidebar from '../components/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/user/actions';
import TelefoneRegistrationDialog from '../components/telefoneRegistrationDialog';

export default function AppRoutes(){

    const user = useSelector(selectUser);

    return (
        <BrowserRouter>
            <Sidebar/>
            <Routes>
                <Route path="/gerarRedacao"  exact 
                element={<GerarRedacao/> }
                />

                {/* <Route path="/depoimentos"  exact 
                element={<Depoimentos/> }
                /> */}
                <Route path="*" element={<Navigate replace to="/gerarRedacao" />} />
            </Routes>

            {user.data.telefone == "" && 
            <TelefoneRegistrationDialog
            open={true}/>}
            
            <ShopDialog/>

            <MessageDialog/>
        </BrowserRouter>
    )
}