import React from 'react'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from '../pages/login'
import Signup from '../pages/signup';
import RecoverPassword from '../pages/recover'

import MessageDialog from '../components/messageDialog';

export default function AuthRoutes(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" exact element={
                    <Login/>
                } 
                />

                <Route path="/signUp" exact element={
                    <Signup/>
                }
                />

                <Route path="/recoverPassword" exact element={
                    <RecoverPassword/>
                } 
                />
                <Route path="*" element={<Navigate replace to="/login" />} />
            </Routes>

            <MessageDialog/>
        </BrowserRouter>
    )
}