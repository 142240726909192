import './styles.scss'

import nomeLogotipo from '../../assets/logoMaior.png'
import backLogin from '../../assets/backLogin.png'
import {Typography, Box, Grid} from '@mui/material';

export default function MasterAuthPage({children}){
    return (
        <Grid 
        container
        sx={{
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            overflowY: 'auto',
        }}>

            <Grid
            item
            xs={12}
            md={6}
            lg={5}
            >
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pt: 6,
                    px: { xs: 5, lg: 16, xl: 22 },
                }}
                >
                    <Box
                    style={{
                        height: '140px',
                        backgroundImage: `url(${nomeLogotipo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',            
                    }}
                    sx={{
                        mb: {xs: 2, lg: 4},
                    }}
                    />

                    {children}

                    <Box 
                    sx={{
                        mt: {xs: 6, lg: 15},
                        textAlign: 'center',
                        mb: 3
                    }}>
                        
                        <Typography
                        variant='body2'
                        color='grey.400'>
                            Pingou @ 2023, Todos os direitos reservados.
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            <Grid
            item
            xs={0}
            md={6}
            lg={7}
            sx={{
                backgroundImage: `url(${backLogin})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}/>

        </Grid>
    )
}