import * as types from "./types"

const initialState = {
    open: false,
    text: '',
    color: ''
}

export function messageReducer(prevState = initialState, action){
    switch (action.type) {
        case types.OPEN:
            return {
                ...prevState,
                open: true,
                text: action.payload.text,
                color: action.payload.color === 'erro' ? "#D00E16" : "#6F62F9"
            }

        case types.CLOSE:
            return {
                ...prevState,
                open: false,
                text: '',
                color: ''
            }

    
        default:
            return prevState;
    }
}