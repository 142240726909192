import * as types from "./types"

const initialState = {
    uid: '',
    data: '',
    isLogged: false
}

export function userReducer(prevState = initialState, action){
    switch (action.type) {
        case types.LOGIN:
            return {
                ...prevState,
                uid: action.payload,
                isLogged: true
            }

        case types.UPDATE_LOGGED_DATA:
            return {
                ...prevState,
                data: action.payload,
                isLogged: true
            }

        case types.LOGOUT:
            return {
                ...prevState,
                uid: '',
                data: '',
                isLogged: false
            }
    
        default:
            return prevState;
    }
}