import {database} from "./firebase";
import { ref, get, set, child, update } from "firebase/database";
import { updateLoggedData } from "../redux/user/actions";

export function createUserInRealtimeDataBase(values, uid){
    set(ref(database, `users/${uid}`), {
        name: values.name,
        email: values.email,
        qntdRedacoes: 0,
        telefone: values.telefone ?? "",
        cameFrom: sessionStorage.getItem("redacaoFrom") ?? "not defined",
        alreadyBought: false
    })
    .then(() => {
        sessionStorage.removeItem("redacaoFrom");
    })
}

export function updateUserInRealtimeDataBase(values, uid){
    update(ref(database, `users/${uid}`), {
        name: values.name,
        email: values.email,
        qntdRedacoes: values.qntdRedacoes,
        telefone: values.telefone ?? "",
        alreadyBought: values.alreadyBought ?? false
    })
}

export function updateRedacoesOfUserInRealtimeDataBase(uid, qntd){
    return function (dispatch) {
        const dbRef = ref(database);
    
        get(child(dbRef, `users/${uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
            let data = {
                name: snapshot.val().name,
                email: snapshot.val().email,
                qntdRedacoes: snapshot.val().qntdRedacoes + qntd,
                telefone: snapshot.val().telefone,
                alreadyBought: snapshot.val().alreadyBought ?? false
            };
            
            updateUserInRealtimeDataBase(data, uid);

            dispatch(updateLoggedData(uid));
        } 
        }).catch((error) => {
            // console.error(error);
        });
    }   
}

export function updateTelefoneOfUserInRealtimeDataBase(uid, telefone){
    return function (dispatch) {
        const dbRef = ref(database);
    
        get(child(dbRef, `users/${uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
            let data = {
                name: snapshot.val().name,
                email: snapshot.val().email,
                qntdRedacoes: snapshot.val().qntdRedacoes,
                telefone: telefone,
                alreadyBought: snapshot.val().alreadyBought ?? false
            };
            
            updateUserInRealtimeDataBase(data, uid);

            dispatch(updateLoggedData(uid));

            
        } 
        }).catch((error) => {
            // console.error(error);
        });
    }   
}

export function updatePayIdOfUserInRealtimeDataBase(uid, payId){
    return function (dispatch) {
        const dbRef = ref(database);
    
        get(child(dbRef, `users/${uid}`))
        .then((snapshot) => {
        if (snapshot.exists()) {
            let data = {
                name: snapshot.val().name,
                email: snapshot.val().email,
                qntdRedacoes: snapshot.val().qntdRedacoes,
                payId: payId,
                telefone: snapshot.val().telefone,
                alreadyBought: snapshot.val().alreadyBought ?? false
            };
            
            updateUserInRealtimeDataBase(data, uid);

            dispatch(updateLoggedData(uid));
        } 
        }).catch((error) => {
            // console.error(error);
        });
    }   
} 

export function setGoogleUserInRealtimeDatabase(user){
    return function (dispatch){
        const dbRef = ref(database);
        get(child(dbRef, `users/${user.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
            if(snapshot.val().name !== user.displayName){
                const values = {
                    name: user.displayName,
                    email: user.email,
                    qntdRedacoes: snapshot.val().qntdRedacoes,
                    telefone: snapshot.val().telefone || "",
                    alreadyBought: snapshot.val().alreadyBought ?? false
                }
                updateUserInRealtimeDataBase(values, user.uid);
                dispatch(updateLoggedData(user.uid));
            }
        } else {
            const values = {
                name: user.displayName,
                email: user.email,
            }
            createUserInRealtimeDataBase(values, user.uid);
            dispatch(updateLoggedData(user.uid));
        } 
        }).catch((error) => {
            // console.error(error);
            return {}
        });
    }
}