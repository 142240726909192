import React, {useEffect, useState} from "react";
import { observer } from "../redux/user/actions";

import AuthRoutes from "./authRoutes";
import AppRoutes from "./appRoutes";

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/user/actions';

import { ThemeProvider } from '@mui/material/styles';
import theme from "../styles/theme";
import { Context } from "./context";

const Router = () => {
  const user = useSelector(selectUser);

  const [openShopDialog, setOpenShopDialog] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  return sessionStorage.getItem("uid") || user.isLogged ? 
    <Context.Provider value={
      {openShopDialog, setOpenShopDialog,
      sidebarOpen, setSidebarOpen}
    }>
      <AppRoutes/>
    </Context.Provider>
    : 
    <AuthRoutes/>
};

export default function App() {

  // useEffect(() => {
  //   const disableRightClick = (event) => {
  //     event.preventDefault();
  //   };

  //   // Função para desabilitar a tecla F12
  //   const disableF12 = (event) => {
  //     if (event.key === 'F12') {
  //       event.preventDefault();
  //     }
  //   };

  //   // Adicionar os event listeners
  //   document.addEventListener('contextmenu', disableRightClick);
  //   document.addEventListener('keydown', disableF12);

  //   // Remover os event listeners quando o componente é desmontado
  //   return () => {
  //     document.removeEventListener('contextmenu', disableRightClick);
  //     document.removeEventListener('keydown', disableF12);
  //   };

    
  // }, []);

  const dispatch = useDispatch();

  useEffect(()=>{
    const from = new URLSearchParams(window.location.search).get('from');

    sessionStorage.setItem("redacaoFrom", from ?? "not defined");
    
    dispatch(observer());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router/>
    </ThemeProvider>
  );
};