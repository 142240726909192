import { Box, Container } from '@mui/material'
import Topbar from '../../components/topbar'

export default function MasterAppPage({children}){
    return (
        <Box
        sx={{
            mb: 5,
        }} 
        >   
        <Topbar/>
            
        {/* <Header/> */}

        {/* <Sidebar/> */}

        <Container>
          {children}
        </Container>
        
    </Box>
    )
}