import React, { useContext, useState } from "react";
import { Box, Typography, IconButton, List, ListItem, ListItemIcon, ListItemButton, Divider, ListItemText, Paper, Drawer, Button, } from "@mui/material";
import { Logout, Close, Article, ArticleOutlined, Person2Outlined, PersonOutline } from '@mui/icons-material'

import { Link } from "react-router-dom";

import { signOutFirebase } from '../../redux/user/actions';

import logo from '../../assets/logoMaior.png'

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/user/actions';

import './styles.scss'
import { Context } from "../../routes/context";

export default function Sidebar() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const {setOpenShopDialog} = useContext(Context);
  const {sidebarOpen, setSidebarOpen} = useContext(Context);

  const toggleDrawer = (open) => (event) => {
    setSidebarOpen(open);
  };

  const Bar = () => (
    <List
    sx={{
        mt: 3,
    }}>
        <ListItem 
        button
        component={Link}
        to="/gerarRedacao"
        disablePadding 
        onClick={toggleDrawer(false)}
        sx={{
            mb: 3
        }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <ArticleOutlined/>
                </ListItemIcon>
                <ListItemText 
                primary={'Gere sua redação automática'}/>
            </ListItemButton>
        </ListItem>

        {/* <ListItem 
        button
        component={Link}
        disablePadding 
        to="/depoimentos"
        onClick={toggleDrawer(false)}
        sx={{
            mb: 3
        }}
        >
            <ListItemButton>
                <ListItemIcon
               >
                    <PersonOutline/>
                </ListItemIcon>
                <ListItemText primary={'Depoimentos'} />
            </ListItemButton>
        </ListItem> */}

        <ListItem 
        button
        component={Link}
        disablePadding 
        onClick={()=> dispatch(signOutFirebase())}
        sx={{
            mb: 3,
        }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <Logout/>
                </ListItemIcon>
                <ListItemText primary={'Deslogar'} />
            </ListItemButton>
        </ListItem>
    </List>
  )

  return (
    <Drawer
    anchor="left"
    open={sidebarOpen}
    onClose={toggleDrawer(false)}
    className="drawer">
        <Box
        sx={{ width: 250 }}
        role="presentation"
        >
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 1
            }}>
                <IconButton onClick={toggleDrawer(false)}>
                    <Close 
                    color="primary"/>
                </IconButton>
            </Box>

            <div className="divLogo">
                <img src={logo} alt="logo redação automática" />
            </div>

            <Bar/>

            <Paper
             sx={{
                backgroundColor: 'primary.main',
                p: 2,
                mb: 5,
            }}
            >
                <Typography
                variant="h6"
                color='common.white'
                sx={{
                    textAlign: 'center'
                }}>
                    Redações Automáticas restantes
                </Typography>

                <Box
                sx={{
                    margin: '10px auto 0',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    width: 100
                }}>
                    <Article 
                    color="common"
                    />
                    <Typography
                    variant="h6"
                    color="common.white"
                    >
                        {user.data.qntdRedacoes}
                    </Typography>
                </Box>
            </Paper>

            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                px: 3,
                gap: 5
            }}>
                <Button 
                color="success"
                variant="contained"
                onClick={()=>{
                    setOpenShopDialog(true);
                }}
                sx={{
                    
                }}>
                        Comprar redações
                </Button>

                <Button
                color="primary"
                variant="contained"
                component={'a'}
                href="mailto:redacaoautomatica@gmail.com"
                >
                    Fale Conosco
                </Button>
            </Box>

            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 5,
                px: 3,
                gap: 5
            }}>
                <Typography
                variant="h6"
                color='primary'>
                    Usuário: {user.data.name}
                </Typography>
            </Box>
        </Box>
    </Drawer>
  );
};
